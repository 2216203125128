import { apiUrl } from "@/_services/config";

export const productsService = {
    getProductDetails,
    getProductsList,
    searchProducts
};

async function getProductsList(code) {

    const response = await fetch(`${apiUrl}api/v1/getNomenByCategory/${code}`)

    const data = await response.json();

    return data;

}

async function getProductDetails(code) {


    const response =
        await fetch(`${apiUrl}api/v1/getNomen/${code}`,{
    })

    const data = await response.json();

    return data;

}

async function searchProducts(query) {

    const response =
        await fetch(`${apiUrl}api/v1/search`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({word:query})
        })

    const data = await response.json();

    return data;

}
