<template>
  <v-container>

    <div v-if="this.loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="orange"
          indeterminate
      ></v-progress-circular>
    </div>



    <h2 class="text-left">{{catName}}</h2>
    <h1 class="text-left"></h1>
          <v-row justify="center">
            <v-col cols="10">
                <v-pagination
                    v-if="pages>1"
                    :color="$root.themeColor"
                    v-model="page"
                    class="my-4"
                    :length="pages"
                ></v-pagination>
              <div v-else style="height: 90px"></div>
            </v-col>
          </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" v-for="(prod, index) in products.slice((page-1)*20,page*20)" :key="index" >
        <ProductItem :item="prod"></ProductItem>
      </v-col>
    </v-row>



  </v-container>
</template>

<script>
import { productsService } from "@/_services/productsService";
import ProductItem from "../components/ProductItem";
  export default {
    name: 'ProductsList',
    components: {ProductItem},
    data(){
      return {
        page: 1,
        loading: false,
        data: null,
        error: null,
        catName: '',
        products: []
      }
    },

    computed: {
      pages(){
        return Math.ceil(this.products.length / 20 )
      }
    },

    created() {
      this.fetchData()
    },

    watch: {
      '$route': 'fetchData',
      // эта функция запускается при любом изменении вопроса
      page: function (newPage, oldPage, category = this.$route.params.id ) {
        console.log(newPage, oldPage, category)
      }
    },

    methods: {
      async fetchData() {
        this.error = this.data = null
        this.loading = true
        const fetchedId = this.$route.params.id
        // replace `getPost` with your data fetching util / API wrapper

        let products = await productsService.getProductsList(fetchedId)

        this.catName = products.lastCat;

        this.products = products.data

        this.page = 1

        this.loading = false
      },
    },

  }
</script>
