<template>
  <v-container>

    <div v-if="this.loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="orange"
          indeterminate
      ></v-progress-circular>
    </div>

    <div v-else-if="this.products.length < 1">
      <v-alert type="warning" class="mx-auto" style="max-width: 400px; margin-top: 200px;">
        <p>По запросу '{{title}}' данные не найдены</p>
        <router-link to="/">На главную</router-link>
      </v-alert>
    </div>

    <div v-else>
      <h2 class="text-left">Результаты поиска по : " {{ title }} "</h2>
      <h1 class="text-left"></h1>
      <v-row justify="center">
        <v-col cols="10">
          <v-pagination
              v-if="pages>1"
              :color="$root.themeColor"
              v-model="page"
              class="my-4"
              :length="pages"
          ></v-pagination>
          <div v-else style="height: 90px"></div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" v-for="(prod, index) in products.slice((page-1)*20,page*20)" :key="index" >
          <ProductItem :item="prod"></ProductItem>
        </v-col>
      </v-row>

    </div>



  </v-container>
</template>

<script>
// import {mapActions, mapGetters} from 'vuex'
import ProductItem from "../components/ProductItem";
import { productsService } from "@/_services/productsService";
export default {
  name: 'SearchPage',
  components: {ProductItem},
  data(){
    return {
      page: 1,
      loading: false,
      data: null,
      error: null,
      title: '',
      products: []
    }
  },

  computed: {
    pages(){
      return Math.ceil(this.products.length / 20 )
    }
  },

  created() {
    this.fetchData()
  },

  watch: {
    '$route': 'fetchData',
    // эта функция запускается при любом изменении вопроса
    page: function (newPage, oldPage, category = this.$route.params.id ) {
      console.log(newPage, oldPage, category)
    }
  },

  methods: {
    async fetchData() {
      this.error = this.data = null
      this.loading = true
      const word = this.$route.query.q

      const searchResult = await productsService.searchProducts(word)


        if (searchResult.error) {
          this.error = true
          this.loading = false
        }

        this.title = searchResult.title
        this.products = searchResult.data

        this.page = 1

        this.loading = false
    },
  }
}
</script>
