<template>
  <div>
    <h1 style="z-index:10;" class="my-10">Cамые популярные товары</h1>

    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" v-for="(prod, index) in popularProducts" :key="index">
        <ProductItem :item="prod"></ProductItem>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import ProductItem from "../components/ProductItem";

export default {
  name: "WelcomePage.vue",
  components: {ProductItem},
  data() {
    return {
      popularProducts: [{
        "id": "8602",
        "title_category": "01.17.02.02 Провода ВВГ по 10м народные",
        "code": "00000028686",
        "title_device": "Провод ВВГмб-П нг(А)-LS 2х2,5 220/380 (10м) Народный",
        "articul": "SQ0117-1110",
        "base_ed": "e8b364ba-b527-11dc-a986-0018f30e4b27",
        "num_from_pack": "100",
        "base_price": "47,19",
        "place_ed": "5a0f7a3d-ed80-11e8-80b0-0050568fb37b",
        "place_ed2": "5a0f7a3d-ed80-11e8-80b0-0050568fb37b",
        "kpp": "true",
        "status": "Продажа разрешена",
        "is_folder": "false"
      }, {
        "id": "6411",
        "title_category": "13.07.01 Удлинители силовые народные",
        "code": "00000016953",
        "title_device": "Удлинитель на катушке силовой народный с 1 выносной роз. ПВС 1300 Вт б/з, 50м",
        "articul": "SQ1307-0404",
        "base_ed": "407c26fb-911f-11dc-92f4-0018f30e4b27",
        "num_from_pack": "1",
        "base_price": "1 648,6",
        "place_ed": "30531151-8519-11e4-915f-000c290ad753",
        "place_ed2": "30531151-8519-11e4-915f-000c290ad753",
        "kpp": "false",
        "status": "Продажа разрешена",
        "is_folder": "false"
      }, {
        "id": "6279",
        "title_category": "03.29.17 Светильники LED ультратонкие даунлайты СВО Народные алюминий",
        "code": "00000025721",
        "title_device": "Светильник ультратонкий встраиваемый светодиодный \"Даунлайт НАРОДНЫЙ\" СВО (белый) 3 Вт 6500К",
        "articul": "SQ0329-0545",
        "base_ed": "407c26fb-911f-11dc-92f4-0018f30e4b27",
        "num_from_pack": "1",
        "base_price": "118,31",
        "place_ed": "deeafe57-0672-11e8-8bd9-005056b8d30d",
        "place_ed2": "deeafe57-0672-11e8-8bd9-005056b8d30d",
        "kpp": "false",
        "status": "Продажа разрешена",
        "is_folder": "false"
      }, {
        "id": "11370",
        "title_category": "01.20.02.01 Шнуры ШВВП по 5м народные",
        "code": "00000028854",
        "title_device": "Шнур ШВВПмб 2х0,75 (5м), белый Народный",
        "articul": "SQ0120-0102",
        "base_ed": "e8b364ba-b527-11dc-a986-0018f30e4b27",
        "num_from_pack": "5",
        "base_price": "18,1",
        "place_ed": "79cdfd69-ee45-11e8-92a1-0050568fb37b",
        "place_ed2": "79cdfd69-ee45-11e8-92a1-0050568fb37b",
        "kpp": "true",
        "status": "Продажа разрешена",
        "is_folder": "false"
      }, {
        "id": "8151",
        "title_category": "01.07.02 Кабели витая пара народные",
        "code": "00000021810",
        "title_device": "Кабель витая пара народный U/UTP Cat 5e 4х2х24AWG (305м) PVC, серый",
        "articul": "SQ0107-0116",
        "base_ed": "2b590880-a736-11dc-aeb9-0018f30e4922",
        "num_from_pack": "1",
        "base_price": "5 521,39",
        "place_ed": "0b502d2c-9b74-11e6-97be-005056b8d30d",
        "place_ed2": "0b502d2c-9b74-11e6-97be-005056b8d30d",
        "kpp": "true",
        "status": "Продажа разрешена",
        "is_folder": "false"
      }, {
        "id": "2861",
        "title_category": "19.01.03 Звонки народные",
        "code": "00000015539",
        "title_device": "Звонок народный ЗББ-Н-11/2-32М (беспр., бат., блистер, откр.код)",
        "articul": "SQ1901-0102",
        "base_ed": "407c26fb-911f-11dc-92f4-0018f30e4b27",
        "num_from_pack": "1",
        "base_price": "302,59",
        "place_ed": "24c7feb0-efd3-11e3-9b2e-000c290ad753",
        "place_ed2": "24c7feb0-efd3-11e3-9b2e-000c290ad753",
        "kpp": "false",
        "status": "Продажа разрешена",
        "is_folder": "false"
      }, {
        "id": "5753",
        "title_category": "01.18.02.03 Провода ПВС по 20м народные",
        "code": "00000033556",
        "title_device": "Провод ПВСмб 2х1 (20м), черный Народный",
        "articul": "SQ0118-0718",
        "base_ed": "e8b364ba-b527-11dc-a986-0018f30e4b27",
        "num_from_pack": "20",
        "base_price": "25,16",
        "place_ed": "8b4d2e6d-20c1-11ea-9be8-0050568fb37b",
        "place_ed2": "8b4d2e6d-20c1-11ea-9be8-0050568fb37b",
        "kpp": "true",
        "status": "Продажа разрешена",
        "is_folder": "false"
      }, {
        "id": "3527",
        "title_category": "09.01.11 Боксы ЩРН-ПМ IP41 Народные",
        "code": "00000032218",
        "title_device": "Бокс ЩРН-ПМ-4 модуля навесной пластик IP41 Народный",
        "articul": "SQ0901-1101",
        "base_ed": "407c26fb-911f-11dc-92f4-0018f30e4b27",
        "num_from_pack": "1",
        "base_price": "205,03",
        "place_ed": "04ab647f-b4f4-11e9-8883-0050568fb37b",
        "place_ed2": "04ab647f-b4f4-11e9-8883-0050568fb37b",
        "kpp": "false",
        "status": "Продажа разрешена",
        "is_folder": "false"
      }
      ]
    }
  },
  created() {
   fetch('https://narodnaya.tdme.ru:5001/api/v1/getSvet')
       .then(response => response.json()
       .then(data => this.$router.push({ name: 'category', params: { id: data.id } })))
  }
}


</script>

<style scoped>

</style>