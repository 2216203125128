<template>
<div v-if="this.loading">
  <v-progress-circular
      :size="70"
      :width="7"
      color="orange"
      indeterminate
  ></v-progress-circular>
</div>



  <div v-else-if="this.error">
    <v-alert type="warning" class="mx-auto" style="max-width: 400px; margin-top: 200px;">
      <p>Данные по товару не найдены</p>
      <router-link to="/">На главную</router-link>
    </v-alert>
  </div>

  <div v-else>

    <v-row>

      <v-col cols="10">
        <router-link
            :to="{ name: 'category', params: { id: this.last_cat_id }}"
            v-slot="{ href, route, navigate}"
            class="float-left ml-2"
            custom
            style="max-width: 100%"
        >

            <a :href="href" @click="navigate" class="text-truncate" style="cursor: pointer; color: grey">
              <span class="text-h6" style="max-width: 100%">←&nbsp; {{last_cat}}</span>
            </a>

        </router-link>
      </v-col>
    </v-row>


    <h1 class="text-left mx-3 mt-2" style="max-width: 80%;">{{ title }}</h1>
    <h2 class="text-left mx-3" style="max-width: 80%; min-height: 64px">{{ article }}</h2>


    <v-row>

      <v-col cols="12" sm="6" md="4" lg="4">
        <v-img
            :src="imgSrc"
            aspect-ratio="1"
            class="mx-4 white lighten-2"
            max-width="100%"
            contain
            @error="onImgError"
        ></v-img>

          <div  v-if="certificates.length > 0" class="ml-2">
            <p class="font-weight-black text-left">Сертификаты:</p>

            <div class="d-flex">
              <v-img
                  max-width="150"
                  :src="certificate_preview"
              />

              <div class="d-block">
                <a  v-for="(item, _idx) in certificates"
                    :href="`https://api2.tdme.ru/download/ImageCertificates/${item.latin_filename}`"
                    target="_blank" :key="_idx" class="doc-link text-left ml-2 mt-2">{{item.rus_filename}}</a>
              </div>

            </div>

          </div>


      </v-col>


      <v-col class="mx-2"
             cols="12" sm="6" md="4" lg="4">

        <v-card
            class="mx-auto"
            max-width="344"
        >
          <v-card-text>
            <p class="display-1 text--primary mb-0">
              {{ price }}
            </p>
          </v-card-text>
        </v-card>

      <div v-if="info.length > 0">
        <h3 class="text-left mt-4">Краткое описание</h3>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
            <tr
                v-for="item in info"
                :key="item._idx"
            >
              <td class="text-left" style="font-weight: bold;">{{ item.ruKey }}</td>
              <td class="text-right">{{ item.value}}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

    <div v-if="type_params.length > 0">
      <h3 class="text-left mt-8">Технические характеристики</h3>
      <v-simple-table dense class="mt-4">
        <template v-slot:default>
          <tbody>
          <tr
              v-for="(item) in type_params.slice(0,limitation_type_params)"
              :key="item.index"
          >
            <td class="text-left">{{ item.type_param }}</td>
            <td class="text-right">{{ item.value_param}}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-btn
          class="float-left"
          color="deep-orange lighten-2"
          style="font-weight: bold;"
          text
          v-if="type_params.length > limitation_type_params"
          @click="limitation_type_params = type_params.length">Все характеристики</v-btn>
    </div>

      </v-col>



    </v-row>





  </div>




</template>

<script>
import certificate_preview from "@/assets/certificate_preview.jpg"
import { productsService } from "@/_services/productsService";
export default {
  name: "ProductDetails",
  data() {
    return {
      loading: false,
      error: null,
      title: '',
      article: '',
      price: '',
      last_cat:'',
      last_cat_id:'',
      type_params: [],
      limitation_type_params: 15,
      info: [],
      failed_image: false,
      certificates: [],
      certificate_preview
    }
  },

  created() {
    this.fetchData()
  },

  computed: {
    imgSrc: function (){
      return this.failed_image ? require(`@/assets/nounit.jpg`) : 'http://tdme.ru/download/catalog/products/img/' + this.article + '.jpg'
    }
  },

  watch: {
    '$route': 'fetchData'
  },

  methods: {
    latToRu(str){
      if(str === 'komplektaciya'){
        return 'Комплектация'
      }
      if(str === 'konstrukziya'){
        return 'Конструкция'
      }
      if(str === 'materialy'){
        return 'Материалы'
      }
      if(str === 'naznachenie'){
        return 'Назначение'
      }
      if(str === 'preimushestva'){
        return 'Преимущества'
      }
      if(str === 'primenenie'){
        return 'Применение'
      }
      if(str === 'upakovka'){
        return 'Упаковка'
      }
      return '';
    },
    async fetchData() {
      this.error = this.data = null
      this.loading = true
      const fetchedId = this.$route.params.id

      const productData = await productsService.getProductDetails(fetchedId)

        if(productData.error){
          this.error = true
          this.loading = false
        }else{
          this.title = productData.description
          this.article = productData.articul
          this.price = productData.base_price

          this.last_cat = productData.last_category
          this.last_cat_id = productData.last_category_id

          this.type_params = productData.type_param
          this.info =  Object.entries(productData.info).map(([key, value]) => {
            const ruKey = this.latToRu(key)
            return ({ruKey,value})
          });

          this.certificates = productData.certificates
        }



        this.loading = false

    },
    onImgError() {
      this.failed_image = true;
    }
  },
}
</script>

<style scoped>
.doc-link{
  color: #fb8c00;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: break-word;
}
</style>