import { apiUrl } from "@/_services/config";

export const certificatesService = {
    getCertificates
};

async function getCertificates() {
    const response = await fetch(`${apiUrl}api/v1/getCertificates`)

    const data = await response.json();
    return data;
}
