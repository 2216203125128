<template>
    <div v-if="this.loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="orange"
          indeterminate
      ></v-progress-circular>
    </div>

    <div v-else-if="this.error">
      <v-alert type="warning" class="mx-auto" style="max-width: 400px; margin-top: 200px;">
        <p>Данные по товару не найдены</p>
        <router-link to="/">На главную</router-link>
      </v-alert>
    </div>

    <div v-else>

      <h1 class="text-left mx-3 mt-2" style="max-width: 80%;">Подбор сертификатов</h1>

      <table style="width: 80%" class="mx-3">
        <thead>
        <tr>
          <th>Наименование продукта</th>
          <th>Сертификат</th>
        </tr>
        </thead>
        <tbody>

        <template v-for="(value, name, _idx) in certificates">
          <tr :key="'a'+_idx">
              <h2>{{name}}</h2>
          </tr>
          <template v-for="(value, name) in value">
            <tr :key="name">
              <td>
                <span>{{name}}</span>
              </td>

              <td>
                <span style="display: block " v-for="(value, _idx) in value" v-bind:key="'c'+_idx">
                  <v-icon right  color="orange darken-2">
                    {{selectIcon(value.latin_filename)}}
                  </v-icon>
                  <a target="_blank"
                     :href="`https://narodnaya.necm.ru/img_certificates2/${value.latin_filename}`">
                 {{value.rus_filename}}</a>
                </span>

              </td>
            </tr>
          </template>
        </template>



        </tbody>
      </table>



    </div>
</template>

<script>
import { certificatesService } from "@/_services/certificatesService";
export default {
  name: "CertificatePage",
  data() {
    return {
      loading: false,
      error: null,
      certificates:[]
    }
  },

  created() {
    this.fetchData()
  },


  methods: {
    async fetchData() {
      this.error = this.data = null
      this.loading = true

      const certificatesData = await certificatesService.getCertificates()

      if(certificatesData.error){
        this.error = true
        this.loading = false
      }else{

        this.certificates = certificatesData.certificates
      }



      this.loading = false

    },
    selectIcon(filename){
      if(filename.includes('.pdf')){
        return 'mdi-file-pdf'
      }else{
        return ' mdi-file-document-outline '
      }
    }
  },
}
</script>

<style scoped>
TABLE {
  border-collapse: collapse;
  width: 300px;
}
TH, TD, TR {
  border: 2px solid black;
  text-align: left;
  margin: 4px;
}
TD > span:not(:last-child){
  border-bottom: 2px solid black;
}
TD span{
  display: inline-block;
  padding: 4px;
}
TH {
  height: 20px;
  vertical-align: center;
  padding: 0;
}
</style>