<template>
  <v-card
      class="mx-auto my-6"
      max-width="374"
  >
    <v-img
        height="250"
        :src=imgSrc
        @error="onImgError"
        contain
    ></v-img>

    <v-card-title>
      <router-link
          :to="{ name: 'product', params: { id: item.articul } }"
          custom
          v-slot="{ href, navigate }"
      >
        <a :href="href" @click="navigate" class="product-header" style="word-break: keep-all">{{ item.title_device }}</a>
      </router-link>
   </v-card-title>

    <v-card-text>
      <v-card-title>{{ item.articul }}</v-card-title>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-text>

      <v-card
          class="my-3"
          flat
          tile
      >
        <p class="subtitle-1 text-right">
          {{ item.base_price }}
        </p>

      </v-card>


    </v-card-text>


<!--    <v-card-text>-->
<!--      <v-chip-group-->
<!--          active-class="deep-purple accent-4 white&#45;&#45;text"-->
<!--          column-->
<!--      >-->
<!--        <v-chip>5:30PM</v-chip>-->

<!--        <v-chip>7:30PM</v-chip>-->

<!--        <v-chip>8:00PM</v-chip>-->

<!--        <v-chip>9:00PM</v-chip>-->
<!--      </v-chip-group>-->
<!--    </v-card-text>-->

<!--    <v-card-actions>-->
<!--      <v-btn-->
<!--          color="deep-purple lighten-2"-->
<!--          text-->
<!--      >-->
<!--        Reserve-->
<!--      </v-btn>-->
<!--    </v-card-actions>-->
  </v-card>
</template>

<script>
export default {
  name: "ProductItem",
  props: ['item'],
  data() {
    return {
      failed_image: false
    }
  },
  computed: {
    imgSrc: function (){
      return this.failed_image ? require(`@/assets/nounit.jpg`) : 'https://tdme.ru/download/catalog/products/img/' + this.item.articul + '.jpg'
    }
  },
  methods:{
    onImgError() {
      this.failed_image = true;
    }
  }
}
</script>

<style scoped>
  .product-header{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>